/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Una de las cosas que echo en falta al haber pasado el blog a un sitio estático son los links bidireccionales. No tanto los links que provengan de fuera que hoy en día van a ser mínimos, como los links internos que permiten explorar el blog, por ejemplo para avanzar hacia el futuro en una serie de posts enlazados entre ellos."), "\n", React.createElement(_components.p, null, "Los links bidireccionales se basan en la capacidad de dado un enlace tradicional, generar un nuevo enlace desde el destino al origen, para más información no puedo dejar de recomendar una de las mejores explicaciones sobre enlaces bidireccionales, ", React.createElement(_components.a, {
    href: "https://maggieappleton.com/bidirectionals"
  }, "la de Maggie Appleton"), "."), "\n", React.createElement(_components.p, null, "Este tipo de enlaces se utiliza en el método Zettelkasten, en los jardines digitales o para generar conversaciones entre blogs y dependiendo de la herramienta de software que estemos utilizando se implementarán de una u otra manera."), "\n", React.createElement(_components.p, null, "Este blog en el pasado utilizaba el sistema de pingbacks de Wordpress que funcionaba realmente bien tanto para links internos como externos."), "\n", React.createElement(_components.h2, null, "Retorno a Webmentions"), "\n", React.createElement(_components.p, null, "Otra implementación de enlaces bidireccionales es Webmentions, algo que ya intenté usar en su momento ", React.createElement(_components.a, {
    href: "https://cyborgspaces.com/2015/01/20/fiasco-del-plugin-webmentions/"
  }, "sin mucho éxito"), " y ahora me planteo recuperar. La ventaja de Webmentions respecto a una solución estática es que no solo me permitiría tener enlaces bidireccionales dentro del blog, si no, además permitir este tipo de interacciones con otros blogs o incluso conectarlo con el Fediverso."), "\n", React.createElement(_components.p, null, "Estaré haciendo pruebas sobre estos temas las próximas semanas."), "\n", React.createElement(_components.p, null, "Foto de ", React.createElement(_components.a, {
    href: "https://unsplash.com/es/@aidamarie_photography%22"
  }, "Aida L"), " en ", React.createElement(_components.a, {
    href: "https://unsplash.com/es/fotos/cadena-de-metal-gris-en-fotografia-de-primer-plano-6y5iySR_UXc"
  }, "Unsplash")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
